import * as React from "react"
import Helmet from "react-helmet"
import { Box } from "@chakra-ui/react"

// markup
const TCPage = () => {
    return (
        <Box className="TCContainer" maxWidth="1269px">
            <Helmet>
                <title>Terms and Conditions | Careway</title>
            </Helmet>
            <h1>Terms and Conditions</h1>
            <p>Terms of Use<br />
            All access to this website is subject to these terms.</p>


            <p><b>Copyright statement</b><br />
            All information and material (both text and images) published on this website is copyright. No information or material from this website may be copied, reproduced, distributed, broadcast, transmitted or otherwise, in any form or by any means, without the express written permission of My Accelerated Care Limited, trading as Careway.</p>


            <p><b>Disclaimer as to accuracy of information</b><br />
            Material published on this website does not necessarily reflect the official views of the organisation, its Board of Directors, shareholders or employees. While all reasonable efforts are made to keep the information on this website accurate and up to date, Careway makes no representation or warranties as to the accuracy or completeness of that information, and accepts no liability or responsibility for any errors or misstatements.</p>
        </Box>
    )
}

export default TCPage
